<div class="ats-container" fxLayout>
  <ng-container *ngIf="!isMobile">
    <div class="ats-sidenav bg-primary" fxLayout #atsSidenav style="margin-top: -70px; z-index: 1000">

      <ul class="ats-sidenav__menu" fxFlex fxLayout="column" fxLayoutAlign="flex-start center">
        <li fxFlex="80" style="list-style: none;">
          <div class="logo__wrapper">
            <a class="logo__link" title="Jobology" fxLayoutAlign="center center">

              <img class="logo__img logo-jobology" fxFlex="80" fxFlexAlign="center"
                [attr.alt]="'logo ' + env.GlobalVars.name" [src]="logoUrl">
            </a>
          </div>
        </li>
        <li class="ats-sidenav__menu-item sourcing" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active">
          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active" [routerLink]="['/sourcing']"
            [queryParams]="{}">
            <mat-icon fxFlex>work</mat-icon>
            Sourcing
          </a>
        </li>
        <!-- <li class="ats-sidenav__menu-item disabled" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active" #tooltip="matTooltip" matTooltipPosition="right"
          matTooltip="La fonctionnalité arrive bientôt">
          <a aria-disabled="true" fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/candidatures-spontanees']">
            <mat-icon fxFlex>email</mat-icon>
            Candidatures spontanées
          </a>
        </li> -->
        <!-- <li class="ats-sidenav__menu-item" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active">
          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/vivier', 'profils']">
            <mat-icon fxFlex>groups</mat-icon>
            Vivier
          </a>
        </li> -->
        <!--  <li class="ats-sidenav__menu-item disabled" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active" #tooltip="matTooltip" matTooltipPosition="right"
          matTooltip="La fonctionnalité arrive bientôt">
          <a aria-disabled="true" fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/ats', 'gerer-mon-portrait-entreprise']">
            <mat-icon fxFlex>devices</mat-icon>
            Ma page entreprise
          </a>
        </li> -->
        <li class="ats-sidenav__menu-item marque-employeur" fxFlex="90px" fxLayout="column"
          fxLayoutAlign="center center" routerLinkActive="active">
          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/marque-employeur']">
            <mat-icon fxFlex>web</mat-icon>
            Marque employeur
          </a>
        </li>

        <li class="ats-sidenav__menu-item formation" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active">
          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/formation-recrutement']">
            <mat-icon fxFlex>devices</mat-icon>
            Formation au recrutement
          </a>
        </li>
        <!--        <li class="ats-sidenav__menu-item disabled" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active" #tooltip="matTooltip" matTooltipPosition="right"
          matTooltip="La fonctionnalité arrive bientôt"> -->
        <li class="ats-sidenav__menu-item" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active">
          <!--     <a aria-disabled="true" fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/ats', 'parametres']"> -->
          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active" [routerLink]="['/parametres']">
            <mat-icon fxFlex>settings</mat-icon>
            Paramètres
          </a>
        </li>
        <li class="ats-sidenav__menu-item" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active">

          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/administration']" *ngIf="displayAdmin">
            <mat-icon fxFlex>domain</mat-icon>
            Administration
          </a>
        </li>
        <!--    <li class="ats-sidenav__menu-item" fxFlex="90px" fxLayout="column" fxLayoutAlign="center center"
          routerLinkActive="active">
          <a fxLayout="column" fxLayoutAlign="center center" routerLinkActive="active"
            [routerLink]="['/ats', 'mon-compte']">
            <mat-icon fxFlex>lock</mat-icon>
            Mon compte
          </a>
        </li> -->
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="isMobile">
    <ats-nav></ats-nav>
  </ng-container>

  <div class="ats-content content__section" fxFlex>

    <router-outlet></router-outlet>

  </div>
</div>