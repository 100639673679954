<mat-nav-list fxLayout>
  <ng-container *ngIf="!isLogged; else isLoggedNav">

    <!-- ICON PERSON TO LOGIN OR SUBSCRIBE-->
    <mat-list-item #loginBtn #navLogin="matMenuTrigger" (mouseenter)="headerService.buttonEnter(navLogin, render)"
      (mouseleave)="headerService.buttonLeave(navLogin, loginBtn, render)"
      class="header-nav__link header-nav__link--login" [matMenuTriggerFor]="loginMenu">
      <a role="button" disableRipple="true" title="Mon compte">
        <mat-icon class="icon--round icon--round-bg" aria-hidden="true">person</mat-icon>
      </a>
    </mat-list-item>

    <!-- Login submenu -->
    <mat-menu class="mat-elevation-z1 menu-border header-nav__menu header-nav__menu--notlogged" #loginMenu="matMenu"
      xPosition="before" [hasBackdrop]="false">
      <ul (mouseenter)="headerService.menuenter()" (mouseleave)="headerService.menuLeave(navLogin, loginBtn, render)"
        style="padding:0">


        <!-- RECRUITERS LOGIN -->
        <li>
          <button fxFlex="220px" [disableRipple]="true" fxLayout mat-menu-item title="se connecter" (click)="login()">
            <div fxFlex>Se connecter</div>
          </button>
        </li>

        <li *ngIf="isLogged">
          <a fxFlex="220px" [disableRipple]="true" fxLayout mat-menu-item href="espace-recruteurs/ctl/logoff.aspx"
            title="se déconnecter">
            <div fxFlex>Se déconnecter</div>
          </a>
        </li>

      </ul>
    </mat-menu>
  </ng-container>

  <ng-template #isLoggedNav>
    <mat-list-item class="header-nav__link  header-nav__link--login" #loggedBtn mat-button #navLogged="matMenuTrigger"
      [matMenuTriggerFor]="accountMenu" style="z-index: 1050; font-weight: 300; text-transform: unset;"
      hasBackdrop="false">
      {{userDatas?.prenom}} {{userDatas?.nom}}
      <i style="margin-left: 15px" aria-hidden="true"
        class="header-nav__link-account-icon icon-css--arrow down small"></i>
    </mat-list-item>

    <ng-container *ngIf="tenantActive">
      <mat-list-item class="header-nav__link header-nav__link header-nav__link--login" mat-button>
        <a class="header-nav__link-account" [routerLink]="['utilisateur', 'mon-compte','informations-de-mon-compte']"
          disableRipple="true" role="button" title="Mon compte" *ngIf="!avatarRecruteur">

          <ng-container *ngIf="userDatas.prenom && userDatas.nom">
            {{userDatas?.prenom[0] + userDatas?.nom[0]}}
          </ng-container>

        </a>

        <a class="header-nav__link-account header-nav__link-account-photo" disableRipple="true" role="button"
          title="Mon compte" [routerLink]="['utilisateur', 'mon-compte','informations-de-mon-compte']"
          *ngIf="avatarRecruteur">
          <img [src]="'data:image/jpeg;base64,'+ avatarRecruteur" alt="" />
        </a>
      </mat-list-item>
    </ng-container>

    <!-- Disabled link if no tenant active-->
    <ng-container *ngIf="!tenantActive">
      <mat-list-item class="header-nav__link header-nav__link header-nav__link--login" mat-button>
        <a class="header-nav__link-account" disableRipple="true" role="button" title="Mon compte"
          *ngIf="!avatarRecruteur">

          <ng-container *ngIf="userDatas.prenom && userDatas.nom">
            {{userDatas?.prenom[0] + userDatas?.nom[0]}}
          </ng-container>
        </a>

        <a class="header-nav__link-account header-nav__link-account-photo" disableRipple="true" role="button"
          title="Mon compte" *ngIf="avatarRecruteur">
          <img [src]="'data:image/jpeg;base64,'+ avatarRecruteur" alt="" />
        </a>

      </mat-list-item>
    </ng-container>


    <!-- (Logged) Account submenu -->
    <mat-menu class="mat-elevation-z1 menu-border header-nav__menu" #accountMenu="matMenu" xPosition="before"
      [hasBackdrop]="true">
      <!--      <ul style="padding: 0" (mouseenter)="headerService.menuenter()"
        (mouseleave)="headerService.menuLeave(navLogged, loggedBtn, render)"> -->
      <ul style="padding: 0">

        <!-- RECRUITERS LOGGED -->
        <!-- COMMENT TAG TO DELETE -->

        <li>
          <div fxFlex="100" fxLayout="column" class="menu-item-container">
            <strong fxFlex> {{userDatas?.prenom}} {{userDatas?.nom}}</strong>
            <div *ngIf="userDatas?.roles">
              {{userDatas.roles[0] | titlecase}}
            </div>

          </div>
        </li>

        <ng-container *ngIf="tenantActive">
          <li>
            <a fxFlex="100" mat-menu-item fxLayout
              [routerLink]="['/utilisateur', 'mon-compte', 'informations-de-mon-compte']" title="Mon Compte">
              <div fxFlex fxFlexOffset="15px">Mon Compte</div>
            </a>
          </li>

          <!--  <li>
            <a fxFlex="275px" mat-menu-item fxLayout [routerLink]="['/ats', 'mon-compte', 'ma-societe']"
              title="Ma société">
              <div fxFlex fxFlexOffset="15px">Ma société</div>
            </a>
          </li>

          <li>
            <a fxFlex="275px" mat-menu-item fxLayout
              [routerLink]="['/ats',  'mon-compte', 'gerer-les-comptes-recruteurs']"
              title="Gérer les comptes recruteurs">
              <div fxFlex fxFlexOffset="15px">Gérer les comptes recruteurs</div>
            </a>
          </li> -->
          <!-- 
          <li>
            <div fxFlex="275px" fxLayout class="menu-item-container">
              <strong fxFlex>{{'Mes Sociétés' | uppercase }}</strong><br>
            </div>
          </li>


          <li>
            <a fxFlex="275px" mat-menu-item fxLayout role="button" [title]="tenantActive?.name">
              <div fxFlex fxFlexOffset="15px" style="text-overflow: ellipsis; overflow-x: clip;;">
                <span style="font-weight: 400"> Connecté(e) à : </span>{{tenantActive?.name }}
              </div>
            </a>
          </li>



          <li *ngIf="tenantsList?.length > 1">
            <button fxFlex="275px" mat-menu-item fxLayout [matMenuTriggerFor]="list" style="padding-left: 30px;">
              Liste des sociétés
            </button>

            <mat-menu #list="matMenu">
              <ng-container *ngFor="let tenant of tenantsList">
                <button mat-menu-item fxFlex="275px" (click)="userService.setTenant(tenant, true)">
                  <ng-container *ngIf="tenant?.id === tenantActive?.id">
                    <strong>
                      {{tenant?.name}}
                    </strong>
                    <span style="font-weight: 400"> : Actif</span>
                  </ng-container>


                  <span *ngIf="tenant?.id !== tenantActive?.id">
                    {{tenant?.name}}
                  </span>
                </button>
              </ng-container>

              <button mat-menu-item fxFlex="275px" [routerLink]="['/mon-compte/tenant']"
                style="border-top: 1px solid grey; text-align: center">
                <strong style="text-decoration: underline;">Voir tous <mat-icon
                    color="primary">add_circle</mat-icon></strong>
              </button>

            </mat-menu>
          </li> -->
        </ng-container>


        <li class="menu-item-container-logout">
          <button fxFlex="100" [disableRipple]="true" role="button" mat-menu-item (click)="logout()"
            title="se déconnecter">
            <mat-icon class="icon--round icon--round-bg">
              power_settings_new</mat-icon>
            ME DECONNECTER
          </button>
        </li>
      </ul>
    </mat-menu>

  </ng-template>


</mat-nav-list>