<!-- <div mat-dialog-close class="popin-closeIcon" *ngIf="!isMobile && data.displayClose">
  <mat-icon svgIcon="close"></mat-icon>
</div>

<div mat-dialog-close class="popin-close" style="top: 0;" *ngIf="isMobile && data.displayClose">
  <mat-icon>close</mat-icon>
</div> -->


<h3 mat-dialog-title>{{data.title}}</h3>
<div mat-dialog-content style="font-weight: 600;">

  <div [innerHTML]="data.content"></div>

</div>

<mat-dialog-actions style="margin-bottom: 0;" fxLayout="column" fxLayout.gt-sm="row"
  [fxLayoutAlign]="data.displayBack || data.displayClose ? 'space-between' : 'center'" fxLayoutGap.lt-md="15px">
  <a mat-button class="btn btn--secondary btn--font-normal" [routerLink]="['/mon-compte/tenant']" mat-dialog-close
    *ngIf="data.displayBack" [ngStyle.lt-md]="{'width': '100%'}">Retour</a>

  <button mat-button class="btn btn--secondary  btn--font-normal" mat-dialog-close *ngIf="data.displayClose"
    [ngStyle.lt-md]="{'width': '100%'}">Fermer</button>


  <a mat-button class="btn btn--default btn--font-normal" [target]="data?.linkTarget ? data?.linkTarget : ''"
    [href]="data?.linkCTA ? data?.linkCTA : env.path.blog.mainUrl + '/les-tarifs/'"
    [ngStyle.lt-md]="{'width': '100%'}">{{data.titleCTA ? data.titleCTA :
    'Gérer mes abonnements'}}</a>

</mat-dialog-actions>