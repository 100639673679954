<div style="min-height: 70vh" fxLayoutAlign="center center">
  <div fxLayout="column">
    <p fxFlex class="text--center" style="font-size: 20px">
      <strong>[403] Accès refusé.</strong><br>
      Vous n'avez pas les autorisations nécessaires pour accéder à la page.
      <!-- <strong style="font-size: 20px">Accès refusé, vous allez être déconnecté.</strong> -->
    </p>
    <br>

    <a routerLink="/" class="text-center">Retour</a>
  </div>
</div>