<h2 fxLayout fxLayoutAlign="flex-start center" mat-dialog-title>
  Bienvenue {{datas?.given_name}} ! <img [src]="env.path.img + '/confettis.png'" alt />
</h2>

<div mat-dialog-content fxLayout="column" ngClass.gt-sm="isDesktop">
  <!-- <p> Vous avez rejoint l'équipe "{{datas?.company}}" en tant que "{{datas?.roles[0]}}".
    <br> -->
  <p>Vous pouvez maintenant utiliser l'application en fonction du rôle qui vous a été attribué !
  </p>

  <p>Ajoutez votre photo pour compléter davantage votre profil :</p>

  <div class="popin-welcome__photo">
    <dragdrop-files #dndFiles [src] displayCTA="true" [isPicture]="true" [displayLabelDescription]="false"
      [descriptionCTA]="descriptionCTA" (fileDropped)="fileUploaded($event)" [formatOutputFile]="true">
    </dragdrop-files>
  </div>

</div>

<mat-dialog-actions style="margin-top: 25px; margin-bottom: 0px">
  <!--   <button mat-button class="btn btn--default btn--font-normal" mat-dialog-close>Commencer à recruter</button> -->

  <button fxFlex.lt-md="100" mat-button class="btn btn--font-normal btn--default" [ngStyle.lt-md]="{'width' : '100%'}"
    type="submit" [class.isLoading]="isSubmitting" (click)="submit()">
    <div fxLayout>
      <mat-spinner diameter="20" *ngIf="isSubmitting"></mat-spinner>
      <div fxFlex> Commencer à recruter</div>
    </div>

  </button>
</mat-dialog-actions>