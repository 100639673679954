<mat-sidenav #mainSidenav mode="side" class="sidenav mat-elevation-z4" (openedChange)="openedChange($event)">
  <ul class="sidenav__list">

    <!-- RECRUITERS-->
    <!-- li class="sidenav__list-item" routerLinkActive="active">
        <a class="sidenav__list-item__link" [routerLink]="['solutions', 'offre-emploi']"
          (click)="headerService.toggle()" title="Déposer une offre d'emploi">
          Déposer une offre d'emploi
        </a>
      </li>

      <li class="sidenav__list-item" routerLinkActive="active">
        <a class="sidenav__list-item__link" [routerLink]="['solutions', 'cvtheque']" (click)="headerService.toggle()"
          title="Rechercher un CV">
          Rechercher un CV
        </a>
      </li-->


    <li class="sidenav__list-item" routerLinkActive="active">
      <a class="sidenav__list-item__link" href="https://www.jobology.fr/blog/" target="_blank" title="Blog Recruteurs"
        (click)="headerService.toggle()">
        blog recruteurs
      </a>
    </li>

    <!--li class="sidenav__list-item sidenav__list-item--expansion" routerLinkActive="active">

        <mat-expansion-panel class="sidenav__list-item__panel mat-elevation-z0" [hideToggle]="false">
          <mat-expansion-panel-header>
            <a class="sidenav__list-item__link" role="button">
              Produits et Tarifs
            </a>
          </mat-expansion-panel-header>
          <a class="sidenav__list-item__link-sub" fxLayout
            href="espace-recruteurs/produits-et-tarifs/offres-demploi.aspx" (click)="headerService.toggle()"
            title="Offres d'emploi">
            <div fxFlex>Offres d'emploi</div>
          </a>
          <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
            href="espace-recruteurs/produits-et-tarifs/produit/idt/632.aspx" title="CVthèque">
            <div fxFlex>CVthèque</div>
          </a>
          <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
            href="espace-recruteurs/produits-et-tarifs/communication.aspx" title="Communication">
            <div fxFlex>Communication</div>
          </a>
          <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
            href="espace-recruteurs/produits-et-tarifs/tous-nos-produits.aspx" title="Tous nos produits">
            <div fxFlex>Tous nos produits</div>
          </a>
        </mat-expansion-panel>

      </li-->

    <!--li class="sidenav__list-item sidenav__list-item--expansion" routerLinkActive="active">
        <mat-expansion-panel class="sidenav__list-item__panel mat-elevation-z0" [hideToggle]="false">
          <mat-expansion-panel-header>
            <a class="sidenav__list-item__link" role="button">
              CVthèque
            </a>
          </mat-expansion-panel-header>
          <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
            href="espace-recruteurs/cv.aspx" title="Consulter la CVthèque">
            <div fxFlex>Consulter la CVthèque</div>
          </a>
          <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
            href="espace-recruteurs/produits-et-tarifs/cvtheque/mes-cv-selectionnes.aspx" title="CVthèque">
            <div fxFlex>Mes CV Sélectionnés</div>
          </a>
        </mat-expansion-panel>
      </li-->

    <!--li class="sidenav__list-item sidenav__list-item--separator"></li>


      <li class="sidenav__list-item" *ngIf="basketCountIsVisible">
        <a class="sidenav__list-item__link sidenav__list-item__link--basket" [routerLink]="['panier']"
          (click)="headerService.toggle()">
          <mat-icon aria-hidden="true" svgIcon="cart" matBadge="{{basketService.cartCount}}" matBadgeColor="accent">
          </mat-icon>
          Mon panier
        </a>
      </li>

      <ng-container *ngIf="headerService.isLogged && authService.roles.recruiter">
        <li class="sidenav__list-item sidenav__list-item--expansion" routerLinkActive="active">
          <mat-expansion-panel class="sidenav__list-item__panel mat-elevation-z0" [hideToggle]="false">
            <mat-expansion-panel-header>
              <a class="sidenav__list-item__link--account sidenav__list-item__link" role="button">{{shortUsername}}</a>
              <strong class="text-color-white" style="line-height: 38px">Mon compte</strong>
            </mat-expansion-panel-header>
            <a *ngIf="authService.roles.coordinator" class="sidenav__list-item__link-sub"
              (click)="headerService.toggle()" fxLayout [attr.href]="'/espace-recruteurs/mon-compte/ma-societe.aspx'"
              title="Ma société">
              <div fxFlex>Ma société</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/mon-compte/informations-de-mon-compte.aspx'"
              title="Informations de mon compte">
              <div fxFlex>Informations de mon compte</div>
            </a>
            <a *ngIf="authService.roles.coordinator" class="sidenav__list-item__link-sub"
              (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/mon-compte/gerer-les-comptes-recruteurs.aspx'"
              title="Gérer les comptes recruteurs">
              <div fxFlex>Gérer les comptes recruteurs</div>
            </a>
            <a *ngIf="authService.roles.coordinator" class="sidenav__list-item__link-sub"
              (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/mon-compte/statistiques-globales-des-offres.aspx'"
              title="Statistiques globales des offres">
              <div fxFlex>Statistiques globales des offres</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/mon-compte/mes-services-disponibles.aspx'"
              title="Mes services disponibles">
              <div fxFlex>Mes services disponibles</div>
            </a>
          </mat-expansion-panel>

        </li>

        <li class="sidenav__list-item sidenav__list-item--expansion" routerLinkActive="active">
          <mat-expansion-panel class="sidenav__list-item__panel mat-elevation-z0" [hideToggle]="false">
            <mat-expansion-panel-header>
              <a class="sidenav__list-item__link" role="button" style="padding-left: 48px"><strong>Offres</strong></a>
            </mat-expansion-panel-header>

            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              href="/espace-recruteurs/offres/deposer-une-offre.aspx" title="Déposer une offre">
              <div fxFlex>Déposer une offre</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/offres/gerer-mes-offres/offres-actives.aspx'" ttitle="Gérer mes offres">
              <div fxFlex>Gérer mes offres</div>
            </a>
          </mat-expansion-panel>
        </li>

        <li class="sidenav__list-item sidenav__list-item--expansion" routerLinkActive="active">
          <mat-expansion-panel class="sidenav__list-item__panel mat-elevation-z0" [hideToggle]="false">
            <mat-expansion-panel-header>
              <a class="sidenav__list-item__link" role="button" style="padding-left: 48px"><strong>CVThèque</strong></a>
            </mat-expansion-panel-header>

            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/cv.aspx'" title="Consulter la CVthèque">
              <div fxFlex>Consulter la CVthèque</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/cvtheque/mes-cv-achetes.aspx'" ttitle="Mes CV achetés">
              <div fxFlex>Mes CV achetés</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/cvtheque/mes-cv-visualises.aspx'" ttitle="Mes CV visualisés">
              <div fxFlex>Mes CV visualisés</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/cvtheque/mes-cv-selectionnes.aspx'" ttitle="Mes CV selectionnés">
              <div fxFlex>Mes CV selectionnés</div>
            </a>
            <a class="sidenav__list-item__link-sub" (click)="headerService.toggle()" fxLayout
              [attr.href]="'/espace-recruteurs/cvtheque/mes-alertes.aspx'" ttitle="Mes alertes">
              <div fxFlex>Mes alertes</div>
            </a>
          </mat-expansion-panel>
        </li>
      </ng-container-->

  </ul>
</mat-sidenav>