// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const defaultEnvironment = {
  path: {
    appRoot: 'app',
    auth: 'https://accounts.preprod.jobtransport.com',
    api: 'https://api.dev.jobology.fr/graphql',
  }
};

