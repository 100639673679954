<!-- <div mat-dialog-close class="popin-closeIcon" *ngIf="!isMobile && data.displayClose">
  <mat-icon svgIcon="close"></mat-icon>
</div>

<div mat-dialog-close class="popin-close" style="top: 0;" *ngIf="isMobile && data.displayClose">
  <mat-icon>close</mat-icon>
</div> -->


<h3 mat-dialog-title>Accès refusé</h3>
<div mat-dialog-content style="font-weight: 600;">

  <div>Vous n'avez pas les droits pour accéder à cette fonctionnalité. Veuillez contacter votre administrateur.</div>

</div>

<div mat-dialog-actions style="margin-bottom: 0;" fxLayout fxLayoutAlign="center">

  <button mat-button class="btn btn--secondary  btn--font-normal" mat-dialog-close>Fermer</button>


</div>