<ng-container *ngIf="!showDiv">
  <loading [static]="true" [defaultAnimation]="false"></loading>
</ng-container>


<ng-container *ngIf="showDiv">
  <div style="min-height: 70vh" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" class="text--center">
      <p fxFlex>
        <strong style="font-size: 20px" *ngIf="!showSessionExpired">Vous avez été déconnecté(e).</strong>

        <strong style="font-size: 20px" *ngIf="showSessionExpired">La session a expiré, vous avez été
          déconnecté(e).</strong>
      </p>
      <p>
        <a routerLink (click)="auth.login()">se reconnecter</a>
      </p>
    </div>

  </div>
</ng-container>