<!-- <div mat-dialog-close class="popin-closeIcon" fxHide.lt-md *ngIf="!datas.isMobile">
  <mat-icon svgIcon="close"></mat-icon>
</div>

<div mat-dialog-close class="popin-close" *ngIf="datas.isMobile" style="top: 0">
  <mat-icon>close</mat-icon>
</div> -->

<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <h2 fxLayout fxLayoutAlign="center center" mat-dialog-title>
    Inviter un/des membre(s)
  </h2>
  <p>Une invitation sera envoyée par e-mail au(x) membre(s) que vous avez ajouté(s). Une fois l'invitation acceptée, ils
    auront accès à l'application Jobology avec le rôle défini.</p>



  <div mat-dialog-content fxLayout="column wrap" fxLayoutAlign="start" ngClass.gt-sm="isDesktop">

    <label for="role">Rôle*</label>
    <mat-form-field appearance="outline" class="select-border">
      <mat-select formControlName="role" id="role" required>
        <ng-container *ngFor="let role of datas.roles">
          <mat-option [id]="role.id" [value]="role.id" *ngIf="role.isPublic">
            {{role.nom}}
          </mat-option>

        </ng-container>

      </mat-select>
    </mat-form-field>


    <label for="users">Email(s)*</label>
    <mat-form-field appearance="outline">
      <mat-chip-list #chipList aria-label="Liste d'emails" formControlName="users">
        <mat-chip *ngFor="let email of emails" (removed)="remove(email)">
          <span [class.mat-error]="!email.valid">{{email.email}}</span>
          <button matChipRemove>
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>

        <input matInput style="margin-top: 5px" #emailInput [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">

      </mat-chip-list>

      <mat-hint>Validez les emails avec , ou avec les touches ENTREE/ESPACE</mat-hint>
      <mat-error *ngIf="form.controls.users.hasError('required')">Champ obligatoire.</mat-error>
      <mat-error *ngIf="form.controls.users.hasError('pattern')">Email(s) invalide(s).</mat-error>
    </mat-form-field>
    <div class="mat-error members-error-exist" *ngIf="errorCanInvite">
      Ce(s) membre(s) existe(nt) déjà ! <br>
      Veuillez nous contacter au 01 77 45 93 55 si vous rencontrez un problème.
      <!--  Emails : <ng-container *ngFor="let item of canInviteList">
        <span *ngIf="!item.canInvite">{{item.email}}</span>
      </ng-container> -->
    </div>

    <br>
    <small>* Champs obligatoires</small>
    <br>

  </div>


  <mat-dialog-actions fxLayout="{{datas.isMobile ? 'column' : 'row'}}" fxLayout.gt-sm="row"
    fxLayout.lt-md="column-reverse" [fxLayoutAlign]="'space-between center'" fxLayoutGap.lt-md="15px">

    <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
      class="btn btn--secondary btn--font-normal" mat-dialog-close (click)="dialogRef.close()" [disabled]="isSubmitting"
      [class.disabled]="isSubmitting">Annuler</button>
    <!--   <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
      class="btn btn--default btn--font-normal" type="submit">Inviter</button> -->

    <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" mat-button class="btn btn--font-normal btn--default"
      [ngStyle.lt-md]="{'width' : '100%'}" type="submit" [class.isLoading]="isSubmitting">
      <div fxLayout>
        <mat-spinner diameter="20" *ngIf="isSubmitting"></mat-spinner>
        <div fxFlex> Inviter</div>
      </div>

    </button>
  </mat-dialog-actions>
</form>