<!-- <mat-icon svgIcon="bear-popin" class="popin-layoutIcon"></mat-icon> -->
<!-- <div mat-dialog-close class="popin-closeIcon" *ngIf="!isMobile">
  <mat-icon svgIcon="close"></mat-icon>
</div>

<div mat-dialog-close class="popin-close" style="top: 0" *ngIf="isMobile">
  <mat-icon>close</mat-icon>
</div> -->

<!--h2 mat-dialog-title>Déposer une nouvelle annonce</h2-->
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center" ngClass.gt-sm="isDesktop">
  <ng-container *ngIf="!displayStepper && !displayMembersAssignment">
    <div style="padding-left: 25px; padding-right: 25px; width: 100%;">
      <h2 class="title-secondary" style="margin-top: 0; text-align: left; width: 100%;">Nouveau poste à pourvoir</h2>
      <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center"
        [ngStyle.gt-sm]="{'width' : '100%', 'height': '430px'}" [ngStyle.lt-md]="{'width' : '100%'}">

        <div>
          <div [ngStyle.gt-sm]="{'width': '70%'}" style="position: relative; margin: 0 auto;">


            <div class="choice" style="margin-bottom: 15px;">
              <p style="margin-bottom: 20px;">
                <strong style="font-size: 18px">
                  Choisissez votre méthode de rédaction :
                </strong>
              </p>

              <mat-radio-button (change)="updateRadio($event)" color="primary" checked>
                Rédiger mon poste à partir de zéro
              </mat-radio-button>

            </div>
            <div fxLayout="column" class="choice">
              <mat-radio-button color="primary" #fileRadio>
                Télécharger une pièce jointe
              </mat-radio-button>

              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="flex-start center" fxFlex
                style="margin: 10px 40px 20px">
                <dragdrop-files #dndFiles [src] [imgThumbnail]="false" [acceptType]="'documents'"
                  (rawFileDropped)="fileUploaded($event)" [descriptionCTA]="descriptionCTA">
                </dragdrop-files>

              </div>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutAlign.gt-sm="space-between center"
          [ngStyle.lt-md]="{'padding-bottom': '60px'}" style="width: 100%;">
          <button fxFlex="100" [ngStyle.lt-md]="{'width': '100%'}" fxFlex.gt-sm="200px" mat-button
            class="btn btn--font-normal btn--secondary" mat-dialog-close>Annuler</button>

          <button fxFlex="100" [ngStyle.lt-md]="{'width': '100%'}" fxFlex.gt-sm="200px" mat-button
            class="btn btn--font-normal btn--default" type="submit"
            (click)="convertFile(); popinService.handleHeightScroll(render);" [class.isLoading]="isSubmitting">

            <div fxLayout fxLayoutAlign="space-between center">
              <mat-spinner diameter="20" *ngIf="isSubmitting"></mat-spinner>
              <div fxFlex>
                Continuer
              </div>
            </div>
          </button>

        </div>
      </div>
    </div>
  </ng-container>

  <mat-horizontal-stepper (selectionChange)="popinService.handleHeightScroll(render); onSelectionChange($event)"
    class="jobdeposit__stepper" labelPosition="bottom" #stepper [disableRipple]="true"
    *ngIf="displayStepper && !displayMembersAssignment" [linear]="true">

    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <mat-step [completed]="step1Completed">
      <loading *ngIf="isLoading" [absolute]="true" [defaultAnimation]="false"></loading>
      <ng-template matStepLabel>Votre <br> annonce</ng-template>
      <h2 class="title-secondary" style="margin-top: 0; margin-bottom: 10px;">Description du poste à pourvoir</h2>

      <form fxLayout="column" [formGroup]="step1Form" (ngSubmit)="onSubmit(step1Form)">
        <p style="margin-bottom: 10px;"><strong>Indiquez le titre, localisation, contrat, mission…</strong></p>
        <div fxLayout="column">

          <div fxLayout="column" fxLayout.gt-sm="row" fxFlex fxLayoutGap="5%">

            <mat-form-field class="form-field" fxFlex="50">
              <label for="libelleDuPoste">Titre<span>*</span></label>
              <input matInput class="input" required #libelleInput formControlName="libelleDuPoste">
              <mat-error>Renseignez le titre du poste</mat-error>
            </mat-form-field>



            <auto-complete-location #autoCompleteComponent [required]="true" [displayCountry]="false"
              [locationId]="jobInfos.job.localiteId" (locationSelected)="locationSelected($event)"
              [appearance]="'standard'" [placeholder]="'Sélectionnez une localisation'" fxFlex="50">
            </auto-complete-location>
          </div>

        </div>

        <div class="jobdeposit__stepper-desc" [class.ng-invalid]="detailsLength >= maxCharLength ||  detailPosteEmpty "
          style="margin-top: 30px">
          <angular-editor id="editor" formControlName="detailPoste" [config]="editorConfig" style="color: black"
            (ngModelChange)="handleCharLength($event, 'details');">
          </angular-editor>
          <small style="align-self: flex-end" [class.error]="detailsLength >= maxCharLength"> {{detailsLength}} /
            {{maxCharLength}}
            caractères</small>
          <!-- <small class="mat-error" *ngIf="step1Form.controls.detailPoste.hasError('required')">Veuillez renseigner la
            description du poste</small> -->

        </div>

        <div class="jobdeposit__stepper-bottom" fxLayout="column" fxLayout.gt-sm="row"
          fxLayoutAlign="space-between center" fxLayoutGap.lt-md="15px" style="margin-top: 0">
          <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
            class="btn btn--font-normal btn--secondary" type="button" (click)="displayStepper=false">Annuler</button>
          <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
            class="btn btn--font-normal btn--default" type="submit">Continuer</button>
        </div>
      </form>
    </mat-step>

    <!-- STEP 2 -->
    <mat-step [completed]="step2Completed">
      <loading *ngIf="isLoading" [absolute]="true" [defaultAnimation]="false"></loading>
      <ng-template matStepLabel>Renseignez les paramètres de<br>votre poste à pourvoir</ng-template>

      <form [formGroup]="step2Form" (ngSubmit)="onSubmitStep2(step2Form)" *ngIf="!successCreate">
        <div fxLayout=" column" fxLayout.gt-sm="row">
          <div fxLayout="column" style="margin: 0 auto" [ngStyle.lt-md]="{'width' : '100%'}"
            [ngStyle.gt-md]="{'width' : '100%'}">
            <h2>Renseignez les paramètres de votre poste à pourvoir</h2>

            <mat-form-field class="select-default" fxFlex appearance="standard" *ngIf="!data?.departement">
              <input type="text" aria-label="Choix du département" class="input" matInput
                formControlName="departementForm" placeholder="Choix du département*" [id]="" [matAutocomplete]="auto">

              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="autoCompleteOptionSelected($event.option, 'departement')"
                class="jobdeposit__departement-autocomplete">
                <mat-option *ngFor="let departement of filteredDepartments | async" [value]="departement.libelle"
                  [id]="departement.id">
                  {{departement.libelle}}

                  <mat-icon *ngIf="departement.typeDepartementId === 1" #tooltip="matTooltip"
                    [matTooltip]="'Département public'" (click)="tooltip.toggle()"
                    matTooltipPosition="right">public</mat-icon>
                  <mat-icon *ngIf="departement.typeDepartementId === 2" #tooltip="matTooltip"
                    [matTooltip]="'Département privé'" (click)="tooltip.toggle()"
                    matTooltipPosition="right">group</mat-icon>
                  <mat-icon *ngIf="departement.typeDepartementId === 3" #tooltip="matTooltip"
                    [matTooltip]="'Département confidentiel'" (click)="tooltip.toggle()"
                    matTooltipPosition="right">lock</mat-icon>
                </mat-option>
              </mat-autocomplete>

              <!-- <mat-label for="departementForm">Choix du département</mat-label>
              <mat-select required formControlName="departementForm">
                <mat-option *ngFor="let departement of departementList" [value]="departement.id" [id]="departement.id">
                  {{departement.libelle}}
                </mat-option>
              </mat-select> -->
              <mat-error *ngIf="step2Form.controls.departementForm.errors?.required">Sélectionnez un
                département</mat-error>

              <mat-error *ngIf="step2Form.controls.departementForm.errors?.incorrect">Sélectionnez un
                département dans la liste</mat-error>
            </mat-form-field>

            <mat-form-field class="select-default" fxFlex appearance="standard" *ngIf="data?.departement">
              <input type="text" aria-label="Choix du département" class="input" matInput
                formControlName="departementForm" placeholder="Choix du département*" readonly
                [id]="data?.departement?.id">

              <mat-error *ngIf="step2Form.controls.departementForm.errors?.required">Sélectionnez un
                département</mat-error>

            </mat-form-field>

            <mat-form-field class="select-default" appearance="standard">
              <input type="text" aria-label="Modèle de flux de recrutement" class="input" matInput
                formControlName="modelesFluxForm" placeholder="Choix d'un modèle de flux de recrutement"
                [matAutocomplete]="autoModels" style="height: 50px" (change)="onChangeModeleFlux($event)">

              <mat-autocomplete autoActiveFirstOption #autoModels="matAutocomplete"
                (optionSelected)="autoCompleteOptionSelected($event.option, 'flux')">
                <mat-option *ngFor="let flux of filteredModelesFluxList | async" [value]="flux.libelle" [id]="flux.id">
                  {{flux.libelle}}
                </mat-option>
              </mat-autocomplete>

              <!-- <mat-label for="modelesFluxForm">Modèle de flux de recrutement</mat-label>

              <mat-select formControlName="modelesFluxForm">
                <mat-option *ngFor="let flux of modelesFluxList" [value]="flux.id" [id]="flux.id">{{flux.libelle}}
                </mat-option>
              </mat-select> -->

              <!-- <mat-error *ngIf="step2Form.controls.modelesFluxForm.errors?.incorrect">Sélectionnez un
                modèle dans la liste</mat-error> -->
            </mat-form-field>

            <mat-form-field class="form-field" fxFlex appearance="standard">
              <input matInput class="input" formControlName="reference" placeholder="Référence*" required>
              <mat-error>Renseignez la référence</mat-error>
            </mat-form-field>

            <br>
            <small>* champs obligatoires</small>
            <!-- </div> -->

          </div>

        </div>


        <div class="jobdeposit__stepper-bottom" fxLayout="column" fxLayout.gt-sm="row"
          fxLayoutAlign="space-between center" fxLayoutGap.lt-md="15px">
          <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
            class="btn btn--font-normal btn--secondary" type="button" (click)="stepper.previous()">Annuler</button>
          <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
            class="btn btn--font-normal btn--default" type="submit">Continuer</button>
        </div>
      </form>

      <!-- <ng-container *ngIf="successCreate">
        <div fxLayout="column" fxLayoutAlign="center center" style="margin: 0 auto; text-align: center;">
          <div fxFlex>
            <strong style="font-size: 34px">Bravo,</strong><br>
            <strong style="font-size: 20px">Votre poste à pourvoir est prêt.</strong>
          </div>
          <br><br>
          <mat-icon svgIcon="bear-handup" style="height: auto; width: 300px;"></mat-icon>
        </div>

      </ng-container> -->
    </mat-step>

    <!-- STEP 3 -->
    <!-- <mat-step>
      <ng-template matStepLabel>Sourcing</ng-template>

      <div class="jobdeposit__sourcing" ngClass.gt-sm="isDesktop">
        <p>Comment souhaitez-vous sourcer des candidats pour ce poste ?</p>


        <mat-radio-group fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="30px" fxLayoutGap.gt-sm="5%"
          (change)="changeSourcing($event)">
          <div fxFlex class="jobdeposit__sourcing-block" *ngFor="let opt of sourcing">
            <mat-radio-button class="radio" color="accent" [value]="opt.value">
              {{ opt.description }}
            </mat-radio-button>

          </div>

        </mat-radio-group>

      </div>

      <ng-container *ngIf="displayOtherSteps">
        <br>
        <p style="font-size: 20px">
          <strong>Vous pouvez choisir sur le(s)quel(s) de nos sites communautaires vous souhaitez diffuser votre
            annonce.</strong>
        </p>


        <p>Transport / Logistique / Distribution / Industrie</p>
        <div class="content__block" style="margin-right: 0; margin-left: 0;" fxLayout="column" fxLayout.gt-sm="row wrap"
          fxLayoutAlign="center center">
          <div fxFlex fxFlex.gt-sm="33">
            <mat-checkbox>
              <img src="{{env.path.img}}/logos_marques/jobtransport.png" alt="" fxFlex>
            </mat-checkbox>
          </div>

          <div fxFlex fxFlex.gt-sm="33">
            <mat-checkbox>
              <img src="{{env.path.img}}/logos_marques/distrijob.png" alt="" fxFlex>
            </mat-checkbox>
          </div>

          <div fxFlex fxFlex.gt-sm="33">
            <mat-checkbox>
              <img src="{{env.path.img}}/logos_marques/clicsea.png" alt="" fxFlex>
            </mat-checkbox>
          </div>
          <div fxFlex fxFlex.gt-sm="33">
            <mat-checkbox>
              <img src="{{env.path.img}}/logos_marques/clicsea.png" alt="" fxFlex>
            </mat-checkbox>
          </div>

          <div fxFlex fxFlex.gt-sm="33">
            <mat-checkbox>
              <img src="{{env.path.img}}/logos_marques/clicpower.png" alt="" fxFlex>
            </mat-checkbox>
          </div>

          <div fxFlex fxFlex.gt-sm="33">
            <mat-checkbox>
              <img src="{{env.path.img}}/logos_marques/clicearth.png" alt="" fxFlex>
            </mat-checkbox>
          </div>

        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px">
          <div fxFlex="30">
            Médical / Paramédical / Services à la personne

            <div class="content__block text--center" style="margin-right: 0; margin-left: 0;" fxLayout="row wrap"
              fxLayoutAlign="center center">
              <div fxFlex>
                <mat-checkbox>
                  <img src="{{env.path.img}}/logos_marques/jobvitae.png" alt="" fxFlex>
                </mat-checkbox>
              </div>
            </div>
          </div>


          <div fxFlex>
            Tourisme / Animation / Loisirs<br><br>

            <div class="content__block text--center" style="margin-right: 0; margin-left: 0;" fxLayout="row wrap"
              fxLayoutAlign="center center">
              <div fxFlex>
                <mat-checkbox>
                  <img src="{{env.path.img}}/logos_marques/clicsport.png" alt="">
                </mat-checkbox>
              </div>

              <div fxFlex>
                <mat-checkbox>
                  <img src="{{env.path.img}}/logos_marques/clictour.png" alt="">
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

      </ng-container>

      <div class="jobdeposit__stepper-bottom" fxLayout="column" fxLayoutAlign="flex-end" fxLayout.gt-sm="row">
        <button fxFlex="30" mat-button class="btn btn--primary" matStepperNext>continuer</button>
      </div>

    </mat-step> -->

    <!-- STEP 4 -->
    <!-- <mat-step *ngIf="displayOtherSteps">
      <loading *ngIf="isLoading" [absolute]="true" [defaultAnimation]="false"></loading>
      <ng-template matStepLabel>Complétez</ng-template>
      <h2>Ajouter le logo de votre entreprise</h2>
      <div class="jobdeposit__stepper-desc" fxLayoutGap="30px" fxLayout="column" fxLayout.gt-sm="row"
        fxLayoutAlign="center center">
        <div fxFlex fxFlex.gt-sm="50">
          <dragdrop-files [src]="companyDatas?.logoBase64" (fileDropped)="getFileDropped($event)">
          </dragdrop-files>
        </div>

        <div fxFlex>
          <mat-checkbox class="checkbox-inline">
            <strong>Je souhaite rendre mon offre anonyme</strong>
            <br>
            En cochant cette case, votre logo
            ne sera pas visible dans le détail de votre offre.
          </mat-checkbox>
        </div>

      </div>

      <div class="jobdeposit__stepper-desc">

        <label><strong>Description de la société</strong></label>
        <br><br>
        <angular-editor id="editor" [(ngModel)]="jobInfos.job.presentationSociete" [config]="editorConfig"
          style="color: black">
        </angular-editor>

      </div>

      <h2>Recevoir les candidatures</h2>

      <div fxLayout="column">
        <mat-form-field fxFlex="100">
          <mat-radio-group labelPosition="after" name="subscriptionType" fxFlex.gt-sm="30">
            <mat-radio-button class="radio" color="accent" value="1"><strong>Par email :</strong> </mat-radio-button>
          </mat-radio-group>

          <input matInput class="input">
          <mat-error>Renseignez le nom du poste</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="100">
          <mat-radio-group labelPosition="after" name="subscriptionType" fxFlex.gt-sm="30">
            <mat-radio-button class="radio" color="accent" value="2"><strong>Par redirection :</strong>
            </mat-radio-button>
          </mat-radio-group>
          <input matInput class="input">
          <mat-error>Renseignez le nom du poste</mat-error>
        </mat-form-field>
      </div>

      <p>Vous pouvre choisir de recevoir les candidatures sur votre messagerie (possibilité de renseigner plusieurs
        adresses mail en les séparant par un ";" sans espace) ou bien de rediriger les candidats sur votre propre site
        pour qu'ils postulent directement dans votre interface.</p>

      <div fxLayout="column" fxLayoutGap="15px">
        <mat-checkbox>
          <strong>Ne pas afficher le salaire</strong>

        </mat-checkbox>
        <mat-checkbox>
          <strong>Option "Mise en avant"</strong>
          <br>
          L'annonce restera en haut du tableau des offres pendant 7 jours.
        </mat-checkbox>

      </div>

      <div class="jobdeposit__stepper-bottom" fxLayout="column" fxLayoutAlign="flex-end" fxLayout.gt-sm="row">
        <button fxFlex="30" mat-button class="btn btn--primary" matStepperNext>continuer</button>
      </div>

    </mat-step> -->

    <!-- STEP 5 -->
    <!-- <mat-step *ngIf="displayOtherSteps">
      <ng-template matStepLabel>Prévisualisez <br>votre annonce</ng-template>
      <div fxLayout fxLayoutGap.lt-md="30px" fxLayoutAlign="center center">
        <div fxFlex="35" fxFlex.gt-sm="50" style="height: 220px" class="text--right">
          <mat-icon svgIcon="bear-handup" style="height: 100%; width: auto;"></mat-icon>
        </div>

        <div fxFlex>
          <strong style="font-size: 34px">Bravo,</strong><br>
          <strong style="font-size: 20px">Votre annonce est prête<br>
            pour sa mise en ligne !
          </strong>
        </div>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around">
        <button fxFlexOrder.lt-md="2" fxFlex.gt-sm="40" mat-button matStepperPrevious class="btn btn--secondary">
          <i class="icon-css--arrow left"></i>
          Modifier mon annonce
        </button>
        <button fxFlexOrder.lt-md="1" fxFlex.gt-sm="40" mat-button class="btn" (click)="confirmJobDeposit()">Confirmer
          le dépôt
          <br>de mon annonce</button>
      </div>


      <job [jobInfos]="jobInfos" [simpleView]="true" ngClass.lt-md="isMobile"></job>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around">
        <button fxFlexOrder.lt-md="2" fxFlex.gt-sm="40" mat-button matStepperPrevious class="btn btn-- btn--secondary">
          <i class="icon-css--arrow left"></i>
          Modifier mon annonce
        </button>
        <button fxFlexOrder.lt-md="1" fxFlex.gt-sm="40" mat-button class="btn" (click)="confirmJobDeposit()">Confirmer
          le dépôt
          <br>de mon
          annonce</button>
      </div>
    </mat-step> -->
  </mat-horizontal-stepper>


  <ng-container *ngIf="displayMembersAssignment">
    <div fxLayout="column" fxLayoutGap="15px" [ngStyle.gt-sm]="{'width' : '90%'}"
      [ngStyle.lt-md]="{'width' : '100%', 'padding-left': '25px', 'padding-right': '25px'}">

      <h2 class="title-secondary">Créer l'équipe du poste «{{jobInfos.job.libelleDuPoste}}»</h2>
      <div>
        Vous pouvez, soit utiliser la même équipe que celle du département dans lequel se trouve le poste à pourvoir,
        soit créer votre propre équipe en sélectionnant chacun des membres.

        <p class="text-fw-semi">
          Souhaitez- vous utiliser la même équipe par défaut que celle du département
          «{{step2Form.controls.departementForm.value}}» ?
        </p>

      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutAlign.gt-sm="space-between center"
        [style]="{'padding-bottom': '35px'}">

        <button fxFlex.lt-md=" 100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
          class="btn btn--font-normal btn--secondary" (click)="getCompanyMembers(true)">Non, créer mon équipe</button>

        <button mat-button class="btn btn--font-normal btn--default" [ngStyle.lt-md]="{'width' : '100%'}"
          [class.isLoading]="isSubmitting" (click)="getCompanyMembers()">

          <div fxLayout fxLayoutAlign="space-between center">
            <mat-spinner diameter="20" *ngIf="isSubmitting"></mat-spinner>
            <div fxFlex>

              Oui, utiliser l'équipe du département

            </div>
          </div>
        </button>

      </div>
    </div>
  </ng-container>
</div>
<!--div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="email" class="btn" (click)="onSubmit()" cdkFocusInitial>valider</button>
</div -->