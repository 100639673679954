import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'jquery', src: '//code.jquery.com/jquery-3.3.1.min.js' },
  { name: 'slick', src: '//unpkg.com/slick-carousel@1.8.1/slick/slick.min.js' }
];

declare var document: any;

@Injectable()
export class ScriptLoaderService {

  private scripts: any = {};
  private env = environment;

  constructor(@Inject(DOCUMENT) private document: Document) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  initGTM() {
    // GTM Tag
    const scriptGTM = this.document.createElement('script');
    scriptGTM.async = true;
    scriptGTM.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.env.gtmID;

    const scriptUA = this.document.createElement('script');
    scriptUA.innerHTML = '    window.dataLayer = window.dataLayer || [];\n' +
      '    function gtag(){dataLayer.push(arguments);}\n' +
      '    gtag(\'js\', new Date());\n' +
      '\n' +
      '    gtag(\'config\', \'' + this.env.gtmID + '\');';

    (<any>this.document.head).prepend(scriptGTM);
    (<any>this.document.body).append(scriptUA);

  }

  initCustomerVoice(formId?) {
    let id = formId ? formId.prod : 'hYXFT7HUykW9iaE4muZ_mjtFghiniRNMrxgn_fPZKNxUMTdURURWREc5T1RLMjBEV0xOUlUxTzFMTy4u';

    if (!environment.production) {
      id = formId.preprod;
    }

    const scriptCV = this.document.createElement('script');
    // scriptCV.async = true;
    scriptCV.src = 'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js';
    this.document.head.prepend(scriptCV);

    const script = this.document.createElement('script');
    script.innerHTML = `function renderSurvey(parentElementId, FirstName, LastName, locale, companyname, sub, guId, email) {
      var se = new SurveyEmbed('${id}', "https://customervoice.microsoft.com/", "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/", "true");
      var context = { "First Name": FirstName, "Last Name": LastName, "locale": locale, "companyname": companyname, "sub": sub, "guId": guId, "Email": email }; se.renderInline(parentElementId, context);
    }`

    /*  script.innerHTML = `function renderSurvey(FirstName, LastName, locale, companyname){
     var se = new SurveyEmbed("hYXFT7HUykW9iaE4muZ_mpKcd-JI-ChNjC_oWxMfT1lUQVJLUUxaNUc0UjdRUENWV1NCMU1MRUxKVy4u","https://customervoice.microsoft.com/","https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/","true");
     var context = {"First Name": FirstName,"Last Name": LastName,"locale": locale,"companyname": companyname,};se.renderButton( context);}`;
  */
    this.document.body.append(script);
  }

  renderCustomerVoice(userDatas?) {
    let user = {
      firstName: userDatas.given_name ? userDatas.given_name : '',
      lastName: userDatas.family_name ? userDatas.family_name : '',
      companyName: userDatas.company ? userDatas.company : '',
      sub: userDatas.sub ? userDatas.sub : '',
      productName: userDatas.productName ? userDatas.productName : '',
      guId: userDatas.sub ? userDatas.sub : '',
      email: userDatas.email ? userDatas.email : ''
    };

    const scriptListener = this.document.createElement('script');
    scriptListener.innerHTML = `
    window.addEventListener('load', function () {
      if (document.getElementById('surveyDiv')) {
           renderSurvey("surveyDiv", '${user.firstName}', '${user.lastName}', '${user.productName}', '${user.companyName}', '${user.sub}', '${user.guId}', '${user.email}'); }
         }, false);`;
    this.document.body.append(scriptListener);

    (<any>window).renderSurvey("surveyDiv", user.firstName, user.lastName, user.productName, user.companyName, user.sub, user.guId, user.email);
    setTimeout(() => {
      (<any>window).scrollTo(0, 0);
    }, 3000);
  }

  initSkalin(userEmail) {
    const script = this.document.createElement('script');
    script.innerHTML = `
    if (window.ska !== undefined) {
      ska(function(skalin){
      skalin.identity({ email: '${userEmail}' }); 
    });
  }`
    this.document.body.append(script);


  }

  initjQuery() {
    (<any>window).$ = (function (d, s, id) {
      let js: any, fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).$ || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "/assets/js/jquery-3.3.1.min.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, "script", ""));
  }

  initSlick() {
    (<any>window).slick = (function (d, s, id) {
      let js: any, fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).slick || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.src = "//unpkg.com/slick-carousel@1.8.1/slick/slick.min.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, "script", ""));
  }

  initPlezi() {
    const script = this.document.createElement('script');
    script.async = true;
    script.src = 'https://api.plezi.co/api/v1/web_forms/scripts?tenant_id=5f2d536fe317a7259082ba99&#038;form_id=5f589279d59cbc1e2dbb3d3e&#038;form_version=3&#038;content_web_form_id=5f649bc9d59cbc159294158b&#038;plz_lang=fr';
    this.document.head.prepend(script);
  }

  initYoutube() {
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}