<div class="dropzone" [ngClass]=" classComponent != '' ? classComponent : ''" fxLayout="column"
  [fxLayout.gt-sm]="fxLayout ? fxLayout : 'row'" fxLayoutAlign="flex-start center" fxLayoutGap.gt-sm="20px"
  fxLayoutGap.lt-md="0" [class.dropzone--fullwidth]="!imgThumbnail" [class.dropzone--videos]="acceptType === 'videos' ">

  <!-- <div class="files-list" *ngIf="imgThumbnail" [class.files-list--round]="isPicture"> -->
  <div class="files-list" *ngIf="imgThumbnail" [style.height]="height ? height: ''">
    <ng-container *ngIf="!imageSrc && !customPlaceholder">
      <img class="img-placeholder" [src]="files[0]?.url" alt="" />
    </ng-container>

    <div class="img-placeholder img-placeholder--custom" fxLayout="column" fxLayoutAlign="center center"
      *ngIf="!imageSrc && customPlaceholder">
      <mat-icon class="icon--xl">image</mat-icon>
      <div class="text-add">Ajouter une photo</div>
    </div>


    <ng-container *ngIf="imageSrc">
      <img [src]="imageSrc" alt="" />
    </ng-container>

  </div>

  <div class="files-drop" dnd (fileDropped)="onFileDropped($event)" [fxLayout]="!imgThumbnail  ? 'column' : 'row'"
    [style.width]="labelWidth ? labelWidth : '100%'">
    <input type="file" [attr.id]="'fileDropRef-' + classComponent" #fileDropRef
      (change)="fileBrowseHandler($event.target.files); inputEventHandler($event)" hidden [accept]="acceptValue">

    <label [attr.for]="'fileDropRef-' + classComponent" *ngIf="imgThumbnail"><span class="files-drop__label-desc"
        *ngIf="displayLabelDescription">Glissez-déposez votre image dans la zone ou
        cliquez dessus pour
        parcourir les fichiers de
        votre ordinateur.</span></label>

    <label [attr.for]="'fileDropRef-' + classComponent" fxLayout="column" fxLayoutAlign="center center"
      *ngIf="!imgThumbnail" #fileDropLabel>
      <mat-icon class="icon--xl" *ngIf="acceptType !== 'videos'">upload_file</mat-icon>
      <mat-icon class="icon--xl" *ngIf="acceptType === 'videos'">videocam</mat-icon>
      <strong *ngIf="!files[0]?.name">Glissez un fichier ici pour l'ajouter</strong>
      <strong *ngIf="files[0]?.name">{{files[0]?.name}}</strong>
    </label>
  </div>

</div>

<div class="files-drop-cta" [ngClass]=" classComponent != '' ? classComponent + '-cta' : ''" fxLayout="column"
  fxLayoutAlign="center center" *ngIf="displayCTA">
  <a mat-button class="btn btn--default btn--font-normal" (click)="fileDropRef.click()">Choisir
    un fichier</a>

  <strong *ngIf="!files[0]?.name && !src">Aucun fichier choisi</strong>

  <ng-container *ngIf="files[0]?.name">
    <div class="files-drop-cta__filename" fxLayout fxLayoutAlign="flex-start center">

      <strong>{{files[0]?.name}}</strong>

      <button mat-icon-button (click)="removeFile(files[0], 'img')">
        <mat-icon>close</mat-icon>
      </button>

    </div>

  </ng-container>

  <ng-container *ngIf="!files[0]?.name && src">
    <div class="files-drop-cta__filename" fxLayout fxLayoutAlign="flex-start center">

      <strong>supprimer l'image</strong>

      <button mat-icon-button (click)="removeFile(files[0])">
        <mat-icon>close</mat-icon>
      </button>

    </div>

  </ng-container>
  <p [innerHTML]="descriptionCTA">

  </p>
</div>

<div class="files-drop-cta" *ngIf="!imgThumbnail">
  <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="flex-start center">
    <a mat-button class="btn btn--default btn--font-normal" (click)="fileDropRef.click()">Choisir
      un fichier</a>
    <strong *ngIf="!files[0]?.name">Aucun fichier choisi</strong>

    <ng-container *ngIf="files[0]?.name">
      <div fxLayout fxLayoutAlign="center center">
        <strong>{{files[0]?.name}}</strong>
        <button mat-icon-button (click)="removeFile(files[0])">
          <mat-icon>close</mat-icon>
        </button>

      </div>

    </ng-container>

  </div>

  <p>
  <p [innerHTML]="descriptionCTA">

  </p>
</div>






<!-- 
<input type="file" (change)="fileChangeEvent($event)" />

<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="5.5 / 3"
  (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()" [resizeToWidth]="455" [resizeToHeight]="250"></image-cropper>

<img [src]="imageSrc" /> -->