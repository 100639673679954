<mat-toolbar ngClass="header__wrapper" #header (scroll)="onScroll($event)" [fxHide]="pwaIsActive">
  <div class="logo" fxLayout fxLayoutAlign="center center" fxFlex.lt-sm="60%" ngClass.gt-sm="desktop"
    *ngIf="displayLogo || isMobile">

    <!-- BURGER NAV -->
    <!-- <button [class.active]="headerService.toggleActive" ngClass="header-nav__link header-nav__link--burger-menu"
      mat-button [disableRipple]="true" (click)="toggle()" aria-label="navigation menu"
      *ngIf="isMobile && isRecruiters">
      <ng-container *ngIf="!headerService.toggleActive; else navOpen">
        <mat-icon color="primary" class="nav-icon" aria-hidden="true" aria-label="open menu">menu
        </mat-icon>
      </ng-container>

      <ng-template #navOpen>
        <mat-icon color="primary" class="nav-icon" aria-hidden="true" aria-label="close menu">close
        </mat-icon>
      </ng-template>

    </button> -->

    <div class="logo__wrapper">
      <!-- Disabled link on logo if no tenant active -->
      <a [routerLink]="['']" class="logo__link" [title]="env.GlobalVars.name"
        (click)="isMobile ? headerService.close() : $event.stopPropagation()" *ngIf="tenantActive">
        <!--svg class="logo__img" fxFlexOffset="15px" fxFlex="75%" fxFlexAlign="center">
          <use [attr.xlink:href]="logoImg"></use>
        </svg-->
        <img class="logo__img" fxFlex="65%" fxFlex.gt-sm="85" fxFlexAlign="center"
          [attr.alt]="'logo ' + env.GlobalVars.name" [src]="env.path.img+'/svg/logo.svg#logo'"
          [class.logo-jobology]="isRecruiters">
      </a>

      <a class="logo__link" [title]="env.GlobalVars.name"
        (click)="isMobile ? headerService.close() : $event.stopPropagation()" *ngIf="!tenantActive">

        <img class="logo__img" fxFlex="65%" fxFlex.gt-sm="85" fxFlexAlign="center"
          [attr.alt]="'logo ' + env.GlobalVars.name" [src]="env.path.img+'/svg/logo.svg#logo'"
          [class.logo-jobology]="isRecruiters">
      </a>
    </div>
  </div>

  <!-- MOBILE HEADER -->
  <ng-container *ngIf="isMobile; else headerDesktop">
    <mat-sidenav-container ngClass="header-nav" fxLayout fxLayoutAlign="flex-end center">

      <mat-sidenav-content fxLayout fxLayoutAlign="center center" fxLayout ngClass="header-nav__wrapper">


        <button class="header-nav__link" mat-button [disableRipple]="true" aria-label="login menu"
          *ngIf="!authService.loginDisplay && isRecruiters">
          <mat-icon color="primary" style="font-size: 31px" class="icon--round icon--round-bg nav-icon"
            aria-hidden="true" aria-label="open menu" (click)="headerService.toggleMyAccount()">person
          </mat-icon>
        </button>


        <button class="header-nav__link" mat-button [disableRipple]="true" aria-label="login menu"
          *ngIf="authService.loginDisplay">
          <span class="header-nav__link-account" (click)="headerService.openMyAccount(userDatas)"
            *ngIf="!avatarRecruteur">{{authService.datas?.shortname}}
          </span>

          <div class="header-nav__link-account header-nav__link-account-photo" *ngIf="avatarRecruteur"
            (click)="headerService.openMyAccount(userDatas)">
            <img [src]="'data:image/jpeg;base64,'+ avatarRecruteur" alt="" />
          </div>

        </button>

      </mat-sidenav-content>
    </mat-sidenav-container>

  </ng-container>

  <!-- desktop header-->
  <ng-template #headerDesktop>
    <div fxLayout fxHide.xs fxShow.gt-sm class="header-nav header-nav--desktop">

      <!-- RECRUITERS -->
      <nav-recruiters [tenantActive]="tenantActive" *ngIf="tenantActive && authService.loginDisplay"
        [subscription]="subscription"></nav-recruiters>

      <div style="display: flex; flex: 1 1 auto;"></div>

      <!--======= RIGHT NAV ========-->
      <nav-right [(isLogged)]="authService.loginDisplay" [(isRecruiters)]="isRecruiters" [isSticky]="isSticky"
        [avatarRecruteur]="avatarRecruteur" [userDatas]="userDatas">
      </nav-right>

    </div>
  </ng-template>

</mat-toolbar>